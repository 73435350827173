import React from "react";
import { ActionTypeProps, initialState, ReducerStateProps } from "./reducers";

export type UseStateProps = {
  dispatch: React.Dispatch<ActionTypeProps>;
  state: ReducerStateProps;
};

export const StateContext = React.createContext<UseStateProps>({ state: initialState, dispatch: () => "" });
export const useContextState = (): UseStateProps => React.useContext(StateContext);

type StateProviderProps = {
  reducer: React.Reducer<ReducerStateProps, ActionTypeProps>;
  initState: ReducerStateProps;
  children: React.ReactNode;
};

const StateProvider: React.FC<StateProviderProps> = ({ reducer, initState, children }) => {
  const [state, dispatch] = React.useReducer<React.Reducer<ReducerStateProps, ActionTypeProps>>(reducer, initState);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider value={{ state, dispatch }}>{children}</StateContext.Provider>
  );
};

export default StateProvider;
