declare global {
  interface Window {
    initialState: {
      antiForgeryTokenInfo: AntiForgeryTokenInfo;
    };
  }
}

interface AntiForgeryTokenInfo {
  tokenHeaderName: string;
  requestToken: string;
}

export const getAntiForgeryTokenInfo = (): AntiForgeryTokenInfo => window.initialState.antiForgeryTokenInfo;
