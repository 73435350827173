import styled from "styled-components";
import { Heading2 } from "@utdanningsdirektoratet/styles/Headings";

export const H2 = styled(Heading2)`
  margin-top: 1rem;
`;

export const Label = styled.div`
  padding-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
`;
