import { createRoot } from "react-dom/client";
import "./index.less";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "./App";
import StateProvider from "./StateProvider";
import reducer, { initialState } from "./reducers";

const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
  },
]);
const container = document.getElementById("app");
const root = createRoot(container!);

root.render(
  <StateProvider reducer={reducer} initState={initialState}>
    <RouterProvider router={router} />
  </StateProvider>
);
