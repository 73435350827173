import React from "react";
import { ApiException } from "../ApiClients";

export const SET_ERROR = "SET_ERROR";
export const initialState: ReducerStateProps = { error: null };

export type ReducerStateProps = {
  error: ApiException | null;
};

type SetErrorType = {
  type: typeof SET_ERROR;
  payload: ApiException;
};

export type ActionTypeProps = SetErrorType;

const reducer: React.Reducer<ReducerStateProps, ActionTypeProps> = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default reducer;
