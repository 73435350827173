import React from "react";
import { Footer } from "@utdanningsdirektoratet/layout";
import { NavLink } from "react-router-dom";
import { CookieBanner, NecessaryCookie, SentryCookieVendor, StatisticsCookie, ApplicationInsightsCookieVendor, MatomoCookieVendor } from "@utdanningsdirektoratet/cookiebanner";
import { onKeyDown } from "@utdanningsdirektoratet/utils/uu";
import routes from "../routes";

export const UdirFooter: React.FC = () => {
  const [cookieState, setCookieState] = React.useState(false);
  const items = [
    <a href="https://www.udir.no/om-udir/" target="_blank" rel="noopener noreferrer">
      Om direktoratet
    </a>,
    <a href="https://www.udir.no/om-udir/kontakt-oss/" target="_blank" rel="noopener noreferrer">
      Kontaktinformasjon
    </a>,
    <NavLink to={routes.personvern}>Personvernserklæring</NavLink>,
    <div role="link" onClick={() => setCookieState(true)} onKeyDown={onKeyDown(() => setCookieState(true))} tabIndex={0}>
      Cookies innstillinger
    </div>,
  ];
  return (
    <>
      <Footer items={items} udirLogo tjenestenavn="Søknad" linkButton={{ href: "https://udir.no", title: "Udir.no" }} />
      <CookieBanner show={cookieState} necessaryCookie={NecessaryCookie([SentryCookieVendor])} cookies={[StatisticsCookie([ApplicationInsightsCookieVendor, MatomoCookieVendor])]} onAccept={() => setCookieState(false)} />
    </>
  );
};
