import React from "react";
import { Input } from "@utdanningsdirektoratet/input";
import { Form, useFormValidation, validationFunctions } from "@utdanningsdirektoratet/form";
import useSWR from "swr";
import { Vedlegg } from "@utdanningsdirektoratet/vedlegg";
import { remove } from "@utdanningsdirektoratet/utils/array";
import { FileInput } from "@utdanningsdirektoratet/fileinput";
import { Button } from "@utdanningsdirektoratet/button";
import styled from "styled-components";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import { UploadDocumentClient } from "../ApiClients";

export type FileUploadingFormProps = {
  onUpload: (files: Array<File>, firstName: string, lastName: string, email: string) => Promise<boolean>;
  disabled?: boolean;
};

const ButtonRow = styled.div`
  margin-top: 2rem;
`;

const VedleggWrapper = styled.div`
  margin-top: 1rem;
`;

const FileInfoDiv = styled.p`
  margin-top: 1rem;
  font-style: italic;
  font-size: 0.8rem;
`;

export const FileUploadingForm: React.FC<FileUploadingFormProps> = ({ onUpload, disabled = false }) => {
  const { data: allowedDocumentTypes } = useSWR("allowedDocumentTypes", () => new UploadDocumentClient().getAllowedDocumentTypes());
  const formValidation = useFormValidation({
    firstName: { value: "", validation: [validationFunctions.required] },
    lastName: { value: "", validation: [validationFunctions.required] },
    email: {
      value: "",
      validation: [validationFunctions.required, validationFunctions.email],
    },
    documents: {
      value: [] as File[],
      validation: [validationFunctions.required],
    },
  });
  const { fields } = formValidation;

  const validateDocuments = () => {
    const errorMessages = fields.documents.value.map((file) => {
      if (!allowedDocumentTypes!.includes(file.type)) {
        return `Filtypen på filen ${file.name} støttes dessverre ikke. Prøv å konverter den til et annet format.`;
      }
      if (file.size > 10000000) {
        return `Filstørrelsen på dokumentet "${file.name}" er for stor. Filstørrelsen må være under 10MB.`;
      }
      if (file.name.length > 255) {
        return "Dokumentet du prøvde å laste opp har for langt filnavn. Filnavnet må være under 255 karakterer.";
      }
      return null;
    });
    return errorMessages;
  };

  const _onSubmit = async () => {
    const errorMessages = validateDocuments();
    if (errorMessages.filter((x) => x != null).length > 0) {
      errorMessages.forEach((error) => {
        newNotification("error", `${error}`);
      });
      return;
    }
    await onUpload(
      formValidation.values.documents,
      formValidation.values.firstName,
      formValidation.values.lastName,
      formValidation.values.email
    );
    formValidation.saveChanges();
    formValidation.resetFields(["documents"]);
  };

  return (
    <Form {...fields} {...formValidation} onSubmit={_onSubmit}>
      {({ onSubmit }) => (
        <>
          <Input aria-label="Input" label="Fornavn" {...fields.firstName} placeholder="Fornavn" disabled={disabled} />
          <Input aria-label="Input" label="Etternavn" {...fields.lastName} placeholder="Etternavn" disabled={disabled} />
          <Input aria-label="Input" label="E-post" {...fields.email} placeholder="E-post" disabled={disabled} />
          <FileInfoDiv>Filtyper som støttes er pdf, jpeg, word-filer, excel-filer, powerpoint-filer og rene tekstfiler.</FileInfoDiv>
          <FileInput
            multiple
            {...fields.documents}
            message="Du må laste opp minst ett dokument"
            onChange={(files) => fields.documents.onChange([...fields.documents.value, ...files])}
            label="Last opp dokumenter"
            disabled={disabled}
            accept="application/x-compressed,
            text/plain,
            text/xml,
            application/pdf,
            image/jpeg,
            text/richtext,
            application/msword,
            application/mspowerpoint,
            application/vnd.ms-excel,
            image/svg+xml,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.openxmlformats-officedocument.wordprocessingml.template,
            application/vnd.openxmlformats-officedocument.presentationml.presentation,
            application/vnd.openxmlformats-officedocument.presentationml.template,
            application/vnd.openxmlformats-officedocument.presentationml.slideshow,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            application/vnd.openxmlformats-officedocument.spreadsheetml.template,
            application/vnd.oasis.opendocument.text,
            application/vnd.oasis.opendocument.spreadsheet"
          />
          <VedleggWrapper>
            {fields.documents.value.map((vedlegg, index) => {
              return (
                <Vedlegg
                  key={index + vedlegg.name}
                  title={vedlegg.name}
                  onDelete={() => fields.documents.onChange(remove(fields.documents.value, index))}
                />
              );
            })}
          </VedleggWrapper>
          <ButtonRow>
            <Button onClick={onSubmit} disabled={disabled}>
              Send inn
            </Button>
            <Button onClick={() => formValidation.resetFields()} disabled={disabled}>
              Nullstill
            </Button>
          </ButtonRow>
        </>
      )}
    </Form>
  );
};
