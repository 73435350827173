import styled from "styled-components";

export const PageContainer = styled.main`
  margin: 3.5rem auto;
  max-width: 89rem;
  padding: 1rem 2rem 8rem 2rem;
`;

export const StyledPageContainer = styled(PageContainer)`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
`;
