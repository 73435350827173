import React from "react";
import { Heading1, Heading2 } from "@utdanningsdirektoratet/styles/Headings";
import { useParams } from "react-router-dom";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import { InpageMessage } from "@utdanningsdirektoratet/inpagemessage";
import { SlowAction, hide, show } from "@utdanningsdirektoratet/slowaction";
import { MainPanel } from "@utdanningsdirektoratet/layout";
import { StyledPageContainer } from "../../components/PagePanels";
import {
  DocumentMetadataViewModel,
  GetDocumentClient,
  GetSoknadsInfoClient,
  SoknadsInfoViewModel,
  SoknadsstatusEnum,
  UploadDocumentClient,
} from "../../ApiClients";
import { H2, Label } from "../../components/Headings";
import { InfoBox } from "../../components/InfoBox";
import { FileUploadingForm } from "../../components/FileUploadingForm";

const Ettersending: React.FC = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const [documents, setDocuments] = React.useState<Array<DocumentMetadataViewModel>>();
  const [soknadsInfo, setSoknadsInfo] = React.useState<SoknadsInfoViewModel>();

  React.useEffect(() => {
    async function fetchData() {
      show();
      const getSoknadsinfoClient = new GetSoknadsInfoClient();
      const soknadsInfoResult = await getSoknadsinfoClient.get(externalId!);
      setSoknadsInfo(soknadsInfoResult);
      const getDocumentsClient = new GetDocumentClient();
      const documentResult = await getDocumentsClient.getDocumentMetadataForSoker(soknadsInfoResult.soknadsbehandlingId);
      setDocuments(documentResult);

      hide();
    }
    fetchData();
  }, [externalId]);

  const onSave = async (files: Array<File>, firstName: string, lastName: string, email: string): Promise<boolean> => {
    let success = false;
    const name = `${firstName} ${lastName}`;
    if (soknadsInfo && files.length > 0) {
      const filesToUpload = files.map((file) => ({
        fileName: file.name,
        data: file,
      }));
      try {
        show();
        const client = new UploadDocumentClient();
        const getDocumentsClient = new GetDocumentClient();
        await client.uploadDocumentFromSoker(soknadsInfo.soknadsbehandlingId, filesToUpload, name, email, soknadsInfo.sokerNavn);
        newNotification("success", "Opplasting vellykket");
        getDocumentsClient.getDocumentMetadataForSoker(soknadsInfo.soknadsbehandlingId).then(setDocuments);
        success = true;
      } catch (error) {
        newNotification("error", "Opplasting feilet", "Noe gikk galt under opplasting av fil. Vennligst prøv igjen.");
      } finally {
        hide();
      }
    }
    return success;
  };

  if (!soknadsInfo) return null;
  const sortedDocuments =
    documents
      ?.sort((a, b) => (b.createdDateUtc > a.createdDateUtc ? 1 : -1))
      .reduce(
        (acc, item) => {
          const formattedDate = formatDate(item.createdDateUtc, "dd.MM.yyyy");
          return {
            ...acc,
            [formattedDate]: acc[formattedDate] ? acc[formattedDate] + 1 : 1,
          };
        },
        {} as Record<string, number>
      ) ?? {};

  return (
    <MainPanel color="white">
      {soknadsInfo.soknadsstatus === SoknadsstatusEnum.FerdigBehandlet && (
        <InpageMessage type="warning" message="Søknaden er ferdig behandlet. Det er derfor ikke mulig å sende inn flere dokumenter." />
      )}
      <StyledPageContainer>
        <section>
          <Heading1>Ettersending av dokumenter</Heading1>
          <p>Her kan du laste opp søknadsdokumenter.</p>
          <FileUploadingForm onUpload={onSave} disabled={soknadsInfo.soknadsstatus === SoknadsstatusEnum.FerdigBehandlet} />
          {Object.keys(sortedDocuments)?.length > 0 && (
            <>
              <H2>Tidligere innsendinger</H2>
              <ul>
                {Object.entries(sortedDocuments).map(([dato, antall], index) => (
                  <div key={index}>
                    {antall} dokument{antall === 1 ? "" : "er"} sendt inn {dato}
                  </div>
                ))}
              </ul>
            </>
          )}
        </section>
        <InfoBox>
          <Heading2>Om Søknaden</Heading2>
          <Label>Søkers navn</Label>
          <div>{soknadsInfo.sokerNavn ?? "--"}</div>
          <Label>Organisasjonsnummer</Label>
          <div>{soknadsInfo.orgnummer ?? "--"}</div>
        </InfoBox>
      </StyledPageContainer>
      <SlowAction />
    </MainPanel>
  );
};

export default Ettersending;
